import { cn } from 'lib/utils';
import { forwardRef, InputHTMLAttributes } from 'react';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  error?: boolean;
}

const base = [
  'border-smd-gray-light',
  'p-2',
  'leading-normal',
  'placeholder:text-smd-secondary',
  'smd-input-focus-primary',
];

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ className, error, ...props }, ref) => {
    const border = error
      ? 'border-red-500 focus:border-red-500 focus:!ring-offset-red-500'
      : 'border-smd focus:border-smd-accent';
    const classes = cn(base, border, className);

    return <input ref={ref} className={classes} type="text" {...props} />;
  }
);

export default Input;
