import { ChevronUp, Pause, Play } from 'components/icons';
import { Dispatch, SetStateAction } from 'react';
import { cn } from 'lib/utils';
import { useAudioPlayerContext } from '../PodcastContext';
import { ProgressBar, PlayerButton } from './';

interface MiniViewProps {
  onExpand: Dispatch<SetStateAction<boolean>>;
}

export default function MiniView({ onExpand }: MiniViewProps) {
  const { isPlaying, setIsPlaying, theme } = useAudioPlayerContext();
  const PlayIcon = isPlaying ? Pause : Play;

  return (
    <div className="fixed bottom-0 z-40 flex h-14 w-full overflow-hidden border bg-white px-4 py-2 md:right-72 md:w-80 md:rounded-t-md md:shadow-lg">
      <div className="flex w-full items-center gap-x-3">
        <PlayerButton
          onClick={() => setIsPlaying(!isPlaying)}
          className={cn(theme.playButton, 'p-2')}
        >
          <PlayIcon className={cn('h-6 w-6 p-1', isPlaying ? '' : 'pr-0.5')} />
        </PlayerButton>
        <div className="flex w-full items-center justify-between gap-x-4">
          <ProgressBar />
          <PlayerButton
            onClick={() => onExpand(true)}
            className={theme.miniPlayerExpandButton}
          >
            <ChevronUp className="h-4 w-4 stroke-3" />
          </PlayerButton>
        </div>
      </div>
    </div>
  );
}
