import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from '@sentry/react';
import classnames from 'classnames';

import { useAds, useParseHomePageData, usePublicResource } from 'hooks';
import { Spinner } from 'components';
import { Ad, AD_SIZE } from 'components/Ad';
import { getDistributionConfig } from 'distribution';
import { SEOMeta } from 'components/SEO';
import { OG_IMAGES } from 'landing/const';
import Error from 'Error';

import { useScroll } from 'components/Scroll';
import {
  CommunityOfTheDay,
  DatePickerTopbar,
  EditorsChoiceJournalsCarousel,
  EducationalSection,
  KeepInTouch,
  StoryMDHealthSection,
  StoryOfTheDay,
} from '../components';

const landingPageTopBar =
  getDistributionConfig(['modifications', 'landingPageTopBar']) ?? true;
const landingPageStoryMDBanner =
  getDistributionConfig(['modifications', 'landingPageStoryMDBanner']) ?? true;

function LandingPageSections({ sections }) {
  const homeConfig = useMemo(() => getDistributionConfig(['home']), []);
  if (!sections) {
    return null;
  }

  return sections.map(({ data, sectionKey, sectionType }, index) => {
    const key = `${sectionKey}_${index}`;

    const backgroundClassName =
      index % 2 === 0 ? 'bg-smd-gray-lightest' : 'bg-white';

    if (homeConfig?.[sectionKey] === false) {
      return null;
    }

    switch (sectionType) {
      case 'expand': {
        if (landingPageStoryMDBanner) {
          return (
            <ErrorBoundary key={key}>
              <StoryMDHealthSection data={data} />
            </ErrorBoundary>
          );
        }
        return null;
      }
      case 'story': {
        return (
          <ErrorBoundary key={key}>
            {landingPageTopBar && (
              <DatePickerTopbar
              // value={new Date()}
              // onChange={(date) => console.log(date)}
              />
            )}
            <StoryOfTheDay data={data} />
          </ErrorBoundary>
        );
      }
      case 'carousel': {
        return (
          <ErrorBoundary key={key}>
            <EditorsChoiceJournalsCarousel
              data={data}
              className={backgroundClassName}
            />
          </ErrorBoundary>
        );
      }
      case 'communities': {
        return (
          <ErrorBoundary key={key}>
            <CommunityOfTheDay data={data} className={backgroundClassName} />
          </ErrorBoundary>
        );
      }
      case 'slideshow': {
        return (
          <ErrorBoundary key={key}>
            <EducationalSection
              key={key}
              data={data}
              className={backgroundClassName}
            />
          </ErrorBoundary>
        );
      }
      case 'update': {
        return (
          <ErrorBoundary key={key}>
            <KeepInTouch data={data} />
          </ErrorBoundary>
        );
      }
      default: {
        return null;
      }
    }
  });
}

function WelcomeStory() {
  const adsConfig = useAds(['placements', 'landing', 'top']);
  const { isScrolled } = useScroll();
  const { data, isFetching, isFetched } = usePublicResource('/stories.json');
  const parsedSections = useParseHomePageData(data);

  const { t } = useTranslation();

  if (isFetching && !isFetched) {
    return (
      <div className="grow">
        <div className="flex-center h-80">
          <Spinner className="h-16 w-16" />
        </div>
      </div>
    );
  }

  const metaValues = {
    title: t('landing.meta-title'),
    description: t('landing.meta-description'),
    image: OG_IMAGES.WELCOME,
  };

  return (
    <>
      <SEOMeta values={metaValues} />
      <div className="isolate grow">
        {adsConfig?.enabled && (
          <Ad
            className={classnames(
              isScrolled
                ? 'top-smd-sticky-spacer-small'
                : 'top-smd-sticky-spacer-large',
              'sticky -z-10 flex grow justify-center py-2'
            )}
            slot={adsConfig?.slot}
            dimensions={{
              base: AD_SIZE.HORIZONTAL.PHONE_BANNER_MEDIUM,
              md: AD_SIZE.HORIZONTAL.LEADER_BOARD,
              // lg: AD_SIZE.HORIZONTAL.SUPER_LEADER_BOARD,
            }}
          />
        )}
        <div className="bg-white">
          <ErrorBoundary fallback={<Error />}>
            <LandingPageSections sections={parsedSections} />
          </ErrorBoundary>
        </div>
      </div>
    </>
  );
}

export default WelcomeStory;
