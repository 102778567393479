import { Bridge, BridgeStore, linkBridge } from '@webview-bridge/web';

export interface BridgeState extends Bridge {
  getTokens: () => Promise<{
    accessToken: string | null;
    refreshToken: string | null;
  }>;
  logoutNative: () => Promise<void>;
  openInAppBrowser: (url: string) => Promise<void>;
}

export const nativeBridge = linkBridge<BridgeStore<BridgeState>>();
