import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cn } from 'lib/utils';

function MultiSelectChipInput({
  value = [],
  onChange,
  options = [],
  ...props
}) {
  const { t } = useTranslation();
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    setSelectedOptions(value);
  }, [value]);

  const optionClassName = (optionValue) => {
    return cn(
      'relative min-w-[44px] cursor-pointer select-none whitespace-nowrap rounded-full border py-1 px-3 focus-visible:ring-offset-0 text-xs',
      selectedOptions.includes(optionValue)
        ? 'bg-smd-accent text-white'
        : 'bg-white'
    );
  };

  const handleOnItemClick = (itemValue) => {
    setSelectedOptions((prev) => {
      const exists = prev.some((x) => x === itemValue);
      let selectedValues = [...prev, itemValue];
      if (exists) {
        selectedValues = prev.filter((x) => x !== itemValue);
      }
      onChange?.(selectedValues);
      return selectedValues;
    });
  };

  useEffect(() => {
    props.setValue(
      `${props.prefix}.${props.namePrefix}.answers`,
      selectedOptions
    );
  }, [props, selectedOptions]);

  return (
    <div className="flex flex-col gap-2">
      <div className="text-smd-xs italic text-smd-gray-dark">
        {t('my-phr.manual-inputs.labels.select-all')}
      </div>
      <div className="flex flex-wrap gap-2">
        {options.map(({ value, label }, index) => {
          return (
            <div
              key={`${index}${value}`}
              className={optionClassName(value)}
              onClick={() => handleOnItemClick(value)}
              data-value={value}
            >
              {label}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default MultiSelectChipInput;
