import { useMemo, useState } from 'react';
import { nativeBridge } from 'utils/nativeBridge';
import { AUTO_OPEN_MODAL_PARAM_NAME } from 'my-phr/const';

const INITIAL_STATE = {
  sourceModalVisible: false,
  showConfirm: false,
  sourceType: null,
};

function useSourceModal() {
  const [state, setState] = useState(INITIAL_STATE);
  const methods = useMemo(() => {
    return {
      closeAll() {
        setState((oldState) => ({
          ...INITIAL_STATE,
          sourceType: oldState?.sourceType,
        }));
      },
      showConfirmModal() {
        setState((oldState) => ({
          sourceModalVisible: false,
          showConfirm: true,
          sourceType: oldState?.sourceType,
        }));
      },
      showSourceModal(sourceType) {
        if (nativeBridge.isWebViewBridgeAvailable) {
          // This is pretty crazy, but it tells the app to open an in-app-browser to the current link with a AUTO_OPEN_MODAL_PARAM_NAME param, which then the in-app-browser-website is responsible for handling in a suitable manner (useOpenSourceModalEffect).
          // This is needed since the in-app-WEBVIEW does not support opening extra tabs, which are needed to connect to EMRs and Wearables, so we open the website in a browser in the app.
          const currentUrl = new URL(window.location.href);
          currentUrl.searchParams.set(AUTO_OPEN_MODAL_PARAM_NAME, sourceType);
          nativeBridge.openInAppBrowser(currentUrl.href);
        } else {
          setState({
            sourceModalVisible: true,
            showConfirm: false,
            sourceType,
          });
        }
      },
    };
  }, []);

  return { state, methods };
}

export default useSourceModal;
