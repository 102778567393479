import { useMemo } from 'react';
import { getCategoryJournalsFilter } from './utils';

const useChannelsCategoriesFiltering = (channels, searchTerm) => {
  return useMemo(
    () =>
      channels
        ?.map((channel) => {
          const { name, description, partner, categories = [] } = channel;

          if (
            name?.toLowerCase().indexOf(searchTerm) > -1 ||
            description?.toLowerCase().indexOf(searchTerm) > -1 ||
            partner?.name?.toLowerCase().indexOf(searchTerm) > -1
          ) {
            return channel;
          }

          const filter = getCategoryJournalsFilter(searchTerm);
          const matchingCategories = categories
            ?.map(({ category, journals }) => ({
              category,
              journals: filter('', journals),
            }))
            .filter(({ journals }) => journals.length);

          if (matchingCategories.length) {
            return { ...channel, categories: matchingCategories };
          }

          return null;
        })
        .filter(Boolean),
    [channels, searchTerm]
  );
};

export default useChannelsCategoriesFiltering;
