import { createContext, useState, useContext, useMemo, useEffect } from 'react';
import { dissoc } from 'ramda';
import { useAuth } from 'Auth';
import { useMedia } from 'hooks';
import { useGetManualInputCategories } from 'hooks/useStoryblockQuery';
import { useSourceModal } from 'my-phr/hooks';
import { usePhrRouteMatch } from '../layout/hooks';

const LayoutProviderContext = createContext(null);

export const INITIAL_PHR_LAYOUT_STATE = {
  editMode: false,
  sources: false,
  filters: false,
  dashboardAddModal: false,
  manualInputModal: false,
  manualInputModalPayload: undefined,
};

export function useLayoutProviderController() {
  const { authenticated } = useAuth();
  const isLarge = useMedia(useMedia.LARGE);
  const { methods: sourcesModalMethods, state: sourcesModalState } =
    useSourceModal();

  const { categories } = useGetManualInputCategories({
    enabled: authenticated,
  });
  const routeMatch = usePhrRouteMatch();

  const [state, setState] = useState({
    ...INITIAL_PHR_LAYOUT_STATE,
    manualInputCategories: categories,
  });

  useEffect(() => {
    setState(() => ({
      ...INITIAL_PHR_LAYOUT_STATE,
      manualInputCategories: categories,
    }));
  }, [categories, routeMatch.isTimeline, routeMatch.isDashboard]);

  const methods = useMemo(() => {
    return {
      closeAll() {
        setState({
          ...INITIAL_PHR_LAYOUT_STATE,
          manualInputCategories: categories,
        });
      },
      toggleEditMode() {
        setState((prev) => ({
          ...INITIAL_PHR_LAYOUT_STATE,
          manualInputCategories: categories,
          editMode: !prev.editMode,
        }));
      },
      toggleSources() {
        setState((prev) => ({
          ...INITIAL_PHR_LAYOUT_STATE,
          manualInputCategories: categories,
          sources: !prev.sources,
        }));
      },
      toggleFilters() {
        setState((prev) => ({
          ...INITIAL_PHR_LAYOUT_STATE,
          filters: !prev.filters,
        }));
      },
      openSources() {
        setState(() => ({ ...INITIAL_PHR_LAYOUT_STATE, sources: true }));
      },
      openFilters() {
        setState(() => ({ ...INITIAL_PHR_LAYOUT_STATE, filters: true }));
      },
      closeFilters() {
        setState(() => ({ ...INITIAL_PHR_LAYOUT_STATE, filters: false }));
      },
      openDashboardAddModal() {
        setState((prev) => ({ ...prev, dashboardAddModal: true }));
      },
      closeDashboardAddModal() {
        setState((prev) => ({
          ...prev,
          dashboardAddModal: false,
          editMode: false,
        }));
      },
      openManualInputModal(payload) {
        setState((prev) => ({
          ...prev,
          manualInputModal: true,
          manualInputModalPayload: payload,
        }));
      },
      closeSourcesModals: sourcesModalMethods.closeAll,
      closeManualInputModal() {
        setState((prev) => ({
          ...prev,
          manualInputModal: false,
          manualInputModalPayload: undefined,
        }));
      },
      ...dissoc('closeAll', sourcesModalMethods),
    };
  }, [categories, sourcesModalMethods]);

  useEffect(() => {
    if (!isLarge) methods.closeAll();
  }, [isLarge, methods]);

  return { state: { ...state, ...sourcesModalState }, methods };
}

function LayoutProvider({ children }) {
  const controller = useLayoutProviderController();

  const value = useMemo(() => controller, [controller]);

  return (
    <LayoutProviderContext.Provider value={value}>
      {children}
    </LayoutProviderContext.Provider>
  );
}

function useLayoutProvider() {
  const context = useContext(LayoutProviderContext);
  if (typeof context === 'undefined') {
    throw new Error('useLayoutProvider must be used within a LayoutProvider');
  }
  return context;
}

export { LayoutProvider, useLayoutProvider };
