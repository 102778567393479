import { useEffect, useState } from 'react';
import classnames from 'classnames';
import { Form, SelectTag } from 'components/forms';
import { useManualInputDefaultProps } from '../hooks';

function SelectChipInput({ options, isQuestionnaire, ...props }) {
  const newProps = useManualInputDefaultProps({
    ...props,
    className: 'pr-8 w-3/4',
  });

  const [selectedOption, setSelectedOption] = useState(null);
  const [name, setName] = useState(newProps.name);

  const optionClassName = (optionValue, complexValue) => {
    return classnames(
      'relative min-w-[44px] cursor-pointer select-none whitespace-nowrap rounded-full border py-1 px-3 text-smd-xs focus-visible:ring-offset-0',
      optionValue === selectedOption || complexValue === selectedOption
        ? 'bg-smd-accent text-white'
        : 'bg-white'
    );
  };

  const handleSelect = (event) => {
    const value = event.currentTarget?.dataset?.value;
    setSelectedOption(value);
    const parsedValue = [JSON.parse(event.currentTarget.dataset.value).value];
    if (isQuestionnaire) {
      setName(`${newProps.prefix}.${newProps.namePrefix}answers`);
      newProps.setValue(
        `${newProps.prefix}.${newProps.namePrefix}answers`,
        parsedValue
      );
    }
    newProps.setValue(`${props.prefix}.${props.name}`, value);
  };

  useEffect(() => {
    const value = newProps?.defaultValues?.[newProps.name];
    if (value) {
      setSelectedOption(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form.Select
      as={SelectTag}
      {...newProps}
      labelProps={newProps.labelProps}
      name={name}
    >
      {options.map(({ value, rangeValue, label }, index) => {
        const optionValue =
          rangeValue !== undefined && rangeValue !== null
            ? JSON.stringify({ value, rangeValue })
            : value;

        return (
          <div
            key={`${index}${value}`}
            className={optionClassName(optionValue, value)}
            onClick={handleSelect}
            data-value={optionValue}
          >
            {label}
          </div>
        );
      })}
    </Form.Select>
  );
}

export default SelectChipInput;
