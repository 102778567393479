import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLibraryAssetRoutedModal } from 'library/hooks';
import { ShareModal, SharePreview } from 'components/Share';
import { cn } from 'lib/utils';
import AudioElement from '../AudioElement';

import {
  ToggleButton,
  Actions,
  FollowOn,
  MiniView,
  ProgressBar,
  Controls,
} from '../components';

import { useAudioPlayerContext } from '../PodcastContext';
import { lightTheme } from '../themes';
import { toLightboxPodcast } from '../utils';

export default function HealthJournalPodcast({ podcast, tocVisible }) {
  const { t } = useTranslation();
  const { audioReactRef, isPlaying, expanded, setExpanded } =
    useAudioPlayerContext();

  useEffect(() => {
    if (isPlaying) {
      audioReactRef.current?.play();
    } else {
      audioReactRef.current?.pause();
    }
  }, [isPlaying, audioReactRef]);

  const { openLibraryAssetModal } = useLibraryAssetRoutedModal();

  const podcastToOpen = toLightboxPodcast(podcast?.libraryContent);

  const onOpenInLightbox = () => openLibraryAssetModal(podcastToOpen);

  const [share, setShare] = useState<any>();

  return (
    <>
      {expanded ? (
        <div className="fixed bottom-0 z-40 w-full bg-white p-0 shadow-inner md:h-40 md:p-4">
          <div className={cn('h-full', tocVisible ? 'w-4/5' : 'w-full')}>
            <div className="relative mx-auto flex h-full w-full max-w-4xl flex-wrap p-4 md:flex-row md:p-0">
              <ToggleButton
                isOpen={expanded}
                onToggle={() => setExpanded(false)}
              />
              <FollowOn
                links={podcast?.links}
                className="order-2 w-1/2 text-smd-2xs md:order-1 md:w-1/4"
              />
              <div className="order-1 flex w-full max-w-4xl flex-col items-center justify-center gap-1 md:order-2 md:w-2/4">
                <div className="text-center text-smd-xs font-semibold text-smd-gray-darker">
                  {podcast?.label}
                </div>
                <ProgressBar />
                <Controls />
              </div>
              <Actions
                onShare={() => setShare(podcastToOpen)}
                onExpand={onOpenInLightbox}
                className="order-3 w-1/2 md:order-3 md:w-1/4"
              />
            </div>
          </div>
        </div>
      ) : (
        <MiniView onExpand={() => setExpanded(true)} />
      )}
      <AudioElement theme={lightTheme} src={podcast?.libraryContent?.url} />
      <ShareModal
        title={t('journals.share.share-with-others')}
        isOpen={share}
        onClose={() => setShare(false)}
        shareUrl={`${window.location.origin}/asset/${podcastToOpen.id}`}
      >
        <SharePreview {...podcastToOpen} description={null} />
      </ShareModal>
    </>
  );
}
