import {
  Pause,
  Play,
  SeekBack,
  SeekForward,
  VolumeMute,
  VolumeUp,
} from 'components/icons';

import { cn } from 'lib/utils';
import { useAudioPlayerContext } from '../PodcastContext';
import PlayerButton from './PlayerButton';

const SEEK_VALUE_SECONDS = 15;

export default function Controls() {
  const {
    duration,
    setIsPlaying,
    isPlaying,
    progress,
    setProgress,
    audioReactRef,
    setMuted,
    muted,
    theme,
  } = useAudioPlayerContext();

  const play = () => setIsPlaying(true);
  const pause = () => setIsPlaying(false);

  const handleProgressChange = (time) => {
    setProgress(time);
    audioReactRef.current.currentTime = time;
  };

  const seekForward = () => {
    const seekTo = progress + SEEK_VALUE_SECONDS;
    if (seekTo <= duration) {
      handleProgressChange(seekTo);
    } else {
      handleProgressChange(duration);
    }
  };

  const seekBackward = () => {
    const seekTo = progress - SEEK_VALUE_SECONDS;
    if (seekTo > 0) {
      handleProgressChange(seekTo);
    } else {
      handleProgressChange(0);
    }
  };

  const PlayIcon = isPlaying ? Pause : Play;
  const VolumeIcon = muted ? VolumeMute : VolumeUp;

  return (
    <div className="flex items-center justify-center space-x-8 py-1.5">
      <div className="invisible h-6 w-6" />
      <PlayerButton className={theme.seekButtons} onClick={seekBackward}>
        <SeekBack className="h-6 w-6" />
      </PlayerButton>
      <PlayerButton
        onClick={isPlaying ? pause : play}
        className={theme.playButton}
      >
        <PlayIcon className={cn('h-6 w-6 p-1', isPlaying ? '' : 'pr-0.5')} />
      </PlayerButton>
      <PlayerButton className={theme.seekButtons} onClick={seekForward}>
        <SeekForward className="h-6 w-6" />
      </PlayerButton>
      <PlayerButton
        className={theme.volumeButton}
        onClick={() => setMuted((muted) => !muted)}
      >
        <VolumeIcon className="h-6 w-6" />
      </PlayerButton>
    </div>
  );
}
