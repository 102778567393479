import React, { forwardRef, useEffect, useState } from 'react';

import { PartnersApi } from 'api';
import { useDefaultToRandomId } from 'hooks';
import { Select } from '.';

export default forwardRef(function SelectPartner({ id, ...props }, ref) {
  const safeId = useDefaultToRandomId(id);
  const [partners, setPartners] = useState([]);
  const [current, setCurrent] = useState();
  useEffect(() => {
    PartnersApi.getAll().then(setPartners);
  }, []);

  function handleChange(val) {
    let current;
    if (val?.target?.value) {
      current =
        partners?.find((partner) => partner.id === val.target.value) || {};
    }
    setCurrent(current);
  }
  return (
    <Select id={safeId} ref={ref} onChange={handleChange} {...props}>
      <option value={current?.id}>{current?.name}</option>
      {partners?.map((partner) => (
        <option key={partner.id} value={partner.id}>
          {partner.name}
        </option>
      ))}
    </Select>
  );
});
