import { Share, Expand } from 'components/icons';
import { cn } from 'lib/utils';
import { useAudioPlayerContext } from '../PodcastContext';
import { PlayerButton } from '.';

interface ActionsProps {
  className?: string;
  onExpand?: Function;
  onShare?: Function;
}

export default function Actions({
  className,
  onShare,
  onExpand,
}: ActionsProps) {
  const { theme } = useAudioPlayerContext();
  return (
    <div
      className={cn(
        'flex items-center justify-end gap-x-4 md:justify-end md:gap-x-2',
        className
      )}
    >
      <PlayerButton onClick={onShare} className={theme.actionButtons}>
        <Share className="h-4 w-4 p-px" />
      </PlayerButton>
      <PlayerButton onClick={onExpand} className={theme.actionButtons}>
        <Expand className="h-4 w-4 p-px" />
      </PlayerButton>
    </div>
  );
}
