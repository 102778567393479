import { cn } from 'lib/utils';
import { forwardRef, InputHTMLAttributes } from 'react';

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  checked?: boolean;
  className?: string;
}

const base = [
  'form-checkbox',
  'text-smd-accent',
  'border border-smd',
  'smd-focus-visible-primary',
];

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ className = '', checked, ...props }, ref) => {
    return (
      <input
        type="checkbox"
        ref={ref}
        className={cn(base, className)}
        checked={checked}
        onChange={() => {}}
        {...props}
      />
    );
  }
);

export default Checkbox;
