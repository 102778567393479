import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useMedia } from 'hooks';
import { useAuth } from 'Auth';
import { CTA } from 'components';
import { DotsVertical } from 'components/icons';
import DropMenu from 'components/DropMenu';
import { Button } from 'components/forms';

function JournalCardActionsWrapper({ children, actions, ...rest }) {
  const { t } = useTranslation();
  const isLarge = useMedia(useMedia.LARGE);

  return (
    <div className="group relative" {...rest}>
      {children}
      {isLarge ? (
        <div className="absolute right-0 top-4 z-20 flex flex-col items-end space-y-2 opacity-0 group-focus-within:opacity-100 group-hover:opacity-100">
          {actions?.map((action, index) => (
            <AddToCollectionButton
              onClick={() => action?.onClick?.()}
              key={index}
            >
              {action?.element}
            </AddToCollectionButton>
          ))}
        </div>
      ) : (
        <DropMenu
          menuButton={
            <DropMenu.Button
              className="absolute bottom-12 right-0 bg-white px-2 pb-10 pt-2"
              aria-label={t('labels.journals.actions')}
            >
              <DotsVertical className="h-6 w-6 text-smd-accent" />
            </DropMenu.Button>
          }
        >
          {actions?.map((action, index) => (
            <DropMenu.Item onClick={() => action?.onClick?.()} key={index}>
              {action?.element}
            </DropMenu.Item>
          ))}
        </DropMenu>
      )}
    </div>
  );
}

function AddToCollectionBtn({ onClick, children }) {
  return (
    <Button.Primary
      size="none"
      className="smd-focus-visible-primary rounded-r-none px-4 py-2 font-semibold opacity-90"
      onClick={onClick}
    >
      {children}
    </Button.Primary>
  );
}

function AddToCollectionCTA({ children }) {
  return (
    <CTA color="primary" ctaProps={{ ctaAction: 'login' }}>
      {children}
    </CTA>
  );
}

function AddToCollectionButton(props) {
  const { authenticated } = useAuth();

  const [ButtonComponent] = authenticated
    ? [AddToCollectionBtn]
    : [AddToCollectionCTA];

  return <ButtonComponent {...props} />;
}

JournalCardActionsWrapper.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      onClick: PropTypes.func,
      element: PropTypes.element,
    })
  ),
};

export default JournalCardActionsWrapper;
