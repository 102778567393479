import { useEffect, useState } from 'react';
import useMedia from './useMedia';

const PADDING_HEIGHT = 24;
const LOGOS_HEIGHT = 18;
const PODCAST_BLOCK_HEIGHT_DESKTOP = 28;
const PODCAST_BLOCK_HEIGHT_MOBILE = 20;
const LINE_HEIGHT_JOURNEY = 24;
const LINE_HEIGHT_JOURNAL = 21;

function useTitleHeight(titleId, bodyStyle, element, type, logos, hasPodcast) {
  const isLarge = useMedia(useMedia.LARGE);

  const [contentLines, setContentLines] = useState('');

  const isJourney = type === 'journey';

  useEffect(() => {
    if (element) {
      const titleElement = document.getElementById(titleId);

      const titleHeight = titleElement?.offsetHeight;
      const lineHeight = isJourney ? LINE_HEIGHT_JOURNEY : LINE_HEIGHT_JOURNAL;
      const logoHeight = logos ? LOGOS_HEIGHT : 0;
      const paddingHeight = PADDING_HEIGHT;

      const podcastHeight = hasPodcast
        ? isLarge
          ? PODCAST_BLOCK_HEIGHT_DESKTOP
          : PODCAST_BLOCK_HEIGHT_MOBILE
        : 0;

      const remainingHeight =
        bodyStyle.height -
        titleHeight -
        logoHeight -
        paddingHeight -
        podcastHeight;

      const shortJournalTitle = remainingHeight > lineHeight * 2;
      const twoLinesTitle =
        remainingHeight > lineHeight && remainingHeight < lineHeight * 3;
      const threeLinesTitle = remainingHeight < lineHeight * 2;
      const longJourneyTitle =
        isJourney &&
        remainingHeight > lineHeight &&
        remainingHeight < lineHeight * 3;

      const shouldHideDescription =
        (hasPodcast && (twoLinesTitle || threeLinesTitle)) ||
        threeLinesTitle ||
        (!shortJournalTitle && !isLarge) ||
        remainingHeight < lineHeight;

      const shouldShowTwoLinesDescription =
        !isJourney && ((twoLinesTitle && isLarge) || !isLarge);

      const shouldShowFourLinesDescription =
        isJourney && remainingHeight > lineHeight * 4;

      setContentLines(
        shouldHideDescription
          ? 'hidden'
          : longJourneyTitle
          ? 'line-clamp-1'
          : shouldShowTwoLinesDescription
          ? 'line-clamp-2'
          : shouldShowFourLinesDescription
          ? 'line-clamp-4'
          : 'line-clamp-3'
      );
    }
  }, [
    bodyStyle.height,
    isJourney,
    isLarge,
    logos,
    element,
    titleId,
    hasPodcast,
  ]);

  return contentLines;
}

export default useTitleHeight;
