import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { cn } from 'lib/utils';
import { useScroll } from 'components/Scroll';
import { useSharedHealthInfoState } from 'my-phr/context/SharedHealthInfoProvider';
import { Button } from 'components/forms';
import { LogoShort } from 'components/icons';
export function SharedHeader() {
  const sharedState = useSharedHealthInfoState();
  const { t } = useTranslation();
  const { isScrolled } = useScroll();

  return (
    <div
      className={cn(
        isScrolled ? 'h-14' : 'h-20 lg:h-28 lg:items-center lg:pt-2',
        'fixed top-0 z-30 flex w-full justify-between bg-white text-smd-main shadow-md lg:z-50'
      )}
    >
      <div className="flex grow flex-row justify-between gap-x-4 px-4 lg:px-8">
        <div className="flex grow flex-row items-center gap-x-4">
          <LogoShort className={cn(isScrolled ? 'h-8' : 'h-8 lg:h-16')} />
          <div className="flex flex-col">
            <div
              className={cn(
                isScrolled ? 'text-smd-base' : 'text-smd-base lg:text-smd-h2',
                'font-semibold'
              )}
            >
              {t('my-phr.shared.header.title', { name: sharedState.name })}
            </div>
            <div
              className={cn(
                isScrolled ? 'text-smd-xs' : 'text-smd-xs lg:text-smd-base'
              )}
            >
              {t('my-phr.shared.header.lastUpdated', {
                date:
                  sharedState.lastUpdated || t('my-phr.shared.header.unknown'),
              })}
            </div>
          </div>
        </div>
        <div className={cn('hidden flex-row items-center gap-x-4 sm:flex')}>
          <Link to="/" target="_blank">
            <Button.Primary>
              {t('my-phr.shared.header.go-to-story-md')}
            </Button.Primary>
          </Link>
        </div>
      </div>
    </div>
  );
}
