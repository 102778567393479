import { useTranslation } from 'react-i18next';
import { cn } from 'lib/utils';
import { nanoid } from 'nanoid';
import { range } from 'ramda';

import { useCarousel } from './context';

const classes = 'flex items-center justify-center space-x-3';

const bulletClasses = [
  'cursor-pointer',
  'rounded-full',
  'w-3 h-3',
  'bg-white border-2 border-smd-gray-light',
  'smd-focus-visible-primary',
];

const inactiveBulletClasses = 'bg-white border-2 border-smd-gray-light';
const activeBulletClasses = 'bg-smd-accent border-none';

export function CarouselPagination({ className, ...rest }) {
  const { t } = useTranslation();
  const { carousel } = useCarousel();

  const bullets = carousel.slides?.length ?? 1;
  const activeIndex = carousel.activeIndex ?? 0;

  return (
    <div className={cn(classes, className)} {...rest}>
      {range(0, bullets).map((bullet) => {
        const isActive = activeIndex === bullet;
        return (
          <button
            key={nanoid()}
            onClick={() => carousel.slideTo(bullet)}
            className={cn(
              bulletClasses,
              isActive ? activeBulletClasses : inactiveBulletClasses
            )}
            aria-label={t('labels.carousel.bullet-button', {
              number: bullet + 1,
            })}
          />
        );
      })}
    </div>
  );
}
