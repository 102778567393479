export function getBaseTimelineCard({
  type,
  title,
  label = {
    value: 'Manual Entry',
    type: 'MANUAL',
  },
  severity,
  reactions,
  subTitle,
  note = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. ',
  linkagesAndLibraryAttachments = {},
  sourceType = 'MANUAL',
  storyblock = {},
  observations = [],
  id,
}) {
  return {
    type,
    className: 'max-w-[800px]',
    title,
    subTitle,
    label,
    timestamp: '2025-02-10T09:08:22Z',
    postedAt: '2025-02-10T09:08:22Z',
    data: {
      effectiveTs: '2025-03-12T09:52:28Z',
      severity,
      reactions,
      additionalInfo: note,
      observations,
      type: type,
      sourceType,
      storyblock,
      id,
    },
    linkagesAndLibraryAttachments,
  };
}

export function mockStringUnits() {
  return new Promise((resolve) => {
    resolve({
      content: [
        {
          type: 'OBSERVATION',
          name: 'CLINICAL INDICATION: Trauma\r\n\r\n\r\nTECHNIQUE: CT of the head, face, and cervical spine was performed without the administration of intravenous contrast. Multiplanar (axial, sagittal, and coronal planes) were reviewed. \r\n\r\n\r\nCOMPARISON: None available\r\n\r\n\r\nFINDINGS: \r\n\r\n\r\nHEAD:\r\n\r\n\r\nPARENCHYMA: No CT evidence of acute territorial infarct. No acute parenchymal hemorrhage.   \r\n\r\n\r\nVENTRICLES: No hydrocephalus.  \r\n\r\n\r\nEXTRA-AXIAL SPACES: No acute extra-axial fluid collections.\r\n\r\n\r\nMASS EFFECT: No mass effect or midline shift. \r\n\r\n\r\nSOFT TISSUES: Right parietal scalp hematoma.\r\n\r\n\r\nCALVARIUM: No acute calvarial fracture detected.\r\n\r\n\r\n\r\n\r\nFACE:\r\n\r\n\r\nSOFT TISSUES: No asymmetric facial soft tissue swelling or hematoma.\r\n\r\n\r\nOSSEOUS STRUCTURES: No acute fracture or dislocation.\r\n\r\n\r\nORBITS: No acute findings.\r\n\r\n\r\nPARANASAL SINUSES: Mild to moderate bilateral paranasal sinus mucosal thickening.\r\n\r\n\r\nTYMPANOMASTOID CAVITIES: Clear.\r\n\r\n\r\n\r\n\r\nCERVICAL SPINE:\r\n\r\n\r\nALIGNMENT: No evidence of traumatic malalignment.\r\n\r\n\r\nBONES: No acute fracture. \r\n\r\n\r\nSOFT TISSUES: No acute findings. \r\n\r\n\r\nDEGENERATIVE: There is multilevel degenerative disc disease with disc space narrowing, posterior disc osteophyte complex, and facet arthropathy causing varying degrees of spinal canal or neural foraminal stenoses including severe left neural foraminal \r\nstenosis at C4-C5.\r\n\r\n\r\nIMAGED LUNG APICES:  Clear\r\n\r\n\r\n\r\n',
          created: '2025-03-13T12:58:40Z',
          postedTs: '2023-03-07T05:00:00Z',
          updatedTs: '2023-03-08T21:23:37Z',
          effectiveTs: '2023-03-07T05:00:00Z',
          storyblockId: 'lqLm11eInA',
          sourceType: 'MANUAL',
          topics: [],
          attachments: [],
          libraryAttachments: [],
          entryTags: [],
          value: '13',
          unit: 'string',
          panelId: 'string-units',
          id: 'YR5kdubYmY',
        },
        {
          type: 'OBSERVATION',
          name: 'Hemoglobin',
          created: '2025-03-13T12:58:40Z',
          postedTs: '2023-03-07T05:00:00Z',
          updatedTs: '2023-03-08T21:23:37Z',
          effectiveTs: '2023-03-07T05:00:00Z',
          storyblockId: 'KAMQLNJT7A',
          sourceType: 'MANUAL',
          userSourceId: 'SvCsSULJr6',
          topics: [],
          attachments: [],
          libraryAttachments: [],
          entryTags: [],
          value:
            'CLINICAL INDICATION: Trauma\r\n\r\n\r\nTECHNIQUE: CT of the head, face, and cervical spine was performed without the administration of intravenous contrast. Multiplanar (axial, sagittal, and coronal planes) were reviewed. \r\n\r\n\r\nCOMPARISON: None available\r\n\r\n\r\nFINDINGS: \r\n\r\n\r\nHEAD:\r\n\r\n\r\nPARENCHYMA: No CT evidence of acute territorial infarct. No acute parenchymal hemorrhage.   \r\n\r\n\r\nVENTRICLES: No hydrocephalus.  \r\n\r\n\r\nEXTRA-AXIAL SPACES: No acute extra-axial fluid collections.\r\n\r\n\r\nMASS EFFECT: No mass effect or midline shift. \r\n\r\n\r\nSOFT TISSUES: Right parietal scalp hematoma.\r\n\r\n\r\nCALVARIUM: No acute calvarial fracture detected.\r\n\r\n\r\n\r\n\r\nFACE:\r\n\r\n\r\nSOFT TISSUES: No asymmetric facial soft tissue swelling or hematoma.\r\n\r\n\r\nOSSEOUS STRUCTURES: No acute fracture or dislocation.\r\n\r\n\r\nORBITS: No acute findings.\r\n\r\n\r\nPARANASAL SINUSES: Mild to moderate bilateral paranasal sinus mucosal thickening.\r\n\r\n\r\nTYMPANOMASTOID CAVITIES: Clear.\r\n\r\n\r\n\r\n\r\nCERVICAL SPINE:\r\n\r\n\r\nALIGNMENT: No evidence of traumatic malalignment.\r\n\r\n\r\nBONES: No acute fracture. \r\n\r\n\r\nSOFT TISSUES: No acute findings. \r\n\r\n\r\nDEGENERATIVE: There is multilevel degenerative disc disease with disc space narrowing, posterior disc osteophyte complex, and facet arthropathy causing varying degrees of spinal canal or neural foraminal stenoses including severe left neural foraminal \r\nstenosis at C4-C5.\r\n\r\n\r\nIMAGED LUNG APICES:  Clear\r\n\r\n\r\n\r\n',
          unit: 'string',
          panelId: 'string-units',
          id: 'KsKvdmuk6h',
        },
      ],
      number: 0,
      size: 8,
      totalElements: 5,
      last: true,
      totalPages: 1,
      first: true,
      numberOfElements: 2,
      empty: false,
    });
  });
}

export function mockDiabetesTracking() {
  return new Promise((resolve) => {
    resolve({
      content: [
        {
          type: 'OBSERVATION',
          authenticationUserId: 'c54c26cf-34a5-4ace-bff0-0332ff879087',
          name: 'Hemoglobin A1C',
          created: '2025-03-12T10:41:02Z',
          postedTs: '2025-03-12T10:41:01Z',
          effectiveTs: '2025-03-11T22:00:00Z',
          storyblockId: '2dZnklzsQd',
          sourceType: 'MANUAL',
          topics: [],
          attachments: [],
          libraryAttachments: [],
          entryTags: [],
          value: '4.2',
          unit: '%{Hb}',
          panelId: 'OtFJ8rIzO9',
          id: 'k12gwpF0GF',
        },
        {
          type: 'OBSERVATION',
          authenticationUserId: 'c54c26cf-34a5-4ace-bff0-0332ff879087',
          name: 'Glucose',
          created: '2025-03-12T10:41:02Z',
          postedTs: '2025-03-12T10:41:01Z',
          effectiveTs: '2025-03-11T22:00:00Z',
          storyblockId: 'LA1G55Mtnd',
          sourceType: 'MANUAL',
          topics: [],
          attachments: [],
          libraryAttachments: [],
          entryTags: [],
          value: '150',
          unit: 'mg/dL',
          panelId: 'OtFJ8rIzO9',
          id: 'ch0EvAr5p6',
        },
        {
          type: 'OBSERVATION',
          authenticationUserId: 'c54c26cf-34a5-4ace-bff0-0332ff879087',
          name: 'Fasting Glucose',
          created: '2025-03-12T10:41:02Z',
          postedTs: '2025-03-12T10:41:01Z',
          effectiveTs: '2025-03-11T22:00:00Z',
          storyblockId: 'Rda9GGbHVd',
          sourceType: 'MANUAL',
          topics: [],
          attachments: [],
          libraryAttachments: [],
          entryTags: [],
          value: '180',
          unit: 'mg/dL',
          panelId: 'OtFJ8rIzO9',
          id: 'JgzgyGCwxd',
        },
        {
          type: 'OBSERVATION',
          authenticationUserId: 'c54c26cf-34a5-4ace-bff0-0332ff879087',
          name: 'Glucose in Urine',
          created: '2025-03-12T10:41:02Z',
          postedTs: '2025-03-12T10:41:01Z',
          effectiveTs: '2025-03-11T22:00:00Z',
          storyblockId: '6qy4XY6T0d',
          sourceType: 'MANUAL',
          topics: [],
          attachments: [],
          libraryAttachments: [],
          entryTags: [],
          value: '20',
          unit: 'mg/dL',
          panelId: 'OtFJ8rIzO9',
          id: 'cC6n6TLN3t',
        },
        {
          type: 'OBSERVATION',
          authenticationUserId: 'c54c26cf-34a5-4ace-bff0-0332ff879087',
          name: 'Ketones in Urine',
          created: '2025-03-12T10:41:02Z',
          postedTs: '2025-03-12T10:41:01Z',
          effectiveTs: '2025-03-11T22:00:00Z',
          storyblockId: 'WdxPL7Es2d',
          sourceType: 'MANUAL',
          topics: [],
          attachments: [],
          libraryAttachments: [],
          entryTags: [],
          value: '3',
          rangeValue: 10,
          panelId: 'OtFJ8rIzO9',
          id: 'lGBH5YXIds',
        },
      ],
      number: 0,
      size: 8,
      totalElements: 5,
      last: true,
      totalPages: 1,
      first: true,
      numberOfElements: 5,
      empty: false,
    });
  });
}
