import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useMedia } from 'hooks';
import { Select, Button } from 'components/forms';
import {
  ChevronDoubleLeft,
  ChevronDoubleRight,
  ChevronLeft,
  ChevronRight,
} from 'components/icons';
import { useScroll } from 'components/Scroll';

const numberBtnStyle = {
  size: 'none',
  className: 'h-10 w-10 p-2',
};

function PageBtn({ index, gotoPage }) {
  const { t } = useTranslation();
  return (
    <Button.Tertiary
      {...numberBtnStyle}
      onClick={() => gotoPage(index)}
      aria-label={t('labels.table.page-button', {
        number: index + 1,
      })}
    >
      {index + 1}
    </Button.Tertiary>
  );
}

function TablePageNumbers({ pageIndex, gotoPage, pageCount }) {
  const { t } = useTranslation();

  const isSmall = useMedia(useMedia.SMALL);
  const hasMoreThan = (range, dir = 'next') => {
    if (pageCount <= range || range === null) {
      return false;
    }
    if (dir === 'next') {
      return pageCount - pageIndex >= range;
    } else {
      return pageIndex - range >= -1;
    }
  };

  const mobileTruncationPrev = isSmall ? 3 : pageIndex === 0 ? null : 0;

  const mobileTruncationNext = isSmall
    ? 3
    : pageIndex === pageCount - 1
    ? null
    : 0;

  return (
    <>
      {hasMoreThan(mobileTruncationPrev, 'prev') && <span>...</span>}
      {hasMoreThan(2, 'prev') && pageIndex === pageCount - 1 && isSmall && (
        <PageBtn index={pageIndex - 2} gotoPage={gotoPage} />
      )}
      {hasMoreThan(1, 'prev') && pageIndex >= 1 && isSmall && (
        <PageBtn index={pageIndex - 1} gotoPage={gotoPage} />
      )}
      <Button.Primary
        {...numberBtnStyle}
        aria-label={t('labels.table.page-button', {
          number: pageIndex + 1,
        })}
      >
        {pageIndex + 1}
      </Button.Primary>
      {hasMoreThan(1) && pageIndex <= pageCount - 2 && isSmall && (
        <PageBtn index={pageIndex + 1} gotoPage={gotoPage} />
      )}
      {hasMoreThan(2) && pageIndex === 0 && isSmall && (
        <PageBtn index={pageIndex + 2} gotoPage={gotoPage} />
      )}
      {hasMoreThan(mobileTruncationNext) && <span>...</span>}
    </>
  );
}

function TablePagination({
  gotoPage,
  previousPage,
  nextPage,
  canPreviousPage,
  canNextPage,
  pageCount,
  totalEntries,
  initialPageSize,
  pageIndex,
  pageOptions,
  pageSize,
  setPageSize,
  pageSizeOptions = [20, 50, 100],
  withBackToTop = true,
}) {
  const { t } = useTranslation();

  const { backToTop } = useScroll();

  const handleTableChangeAndScrollToTopIfEnabled = (fn, params) => {
    fn?.(params);
    withBackToTop && backToTop();
  };

  const shouldHidePagination =
    initialPageSize === pageSize && (!pageCount || pageCount <= 1);

  if (shouldHidePagination) return null;

  return (
    <div className="flex flex-col items-center justify-between space-y-2 p-4 sm:space-y-0 md:flex-row">
      <div className="flex items-center space-x-2">
        <Button.Primary
          size="none"
          className="p-2"
          onClick={() => handleTableChangeAndScrollToTopIfEnabled(gotoPage, 0)}
          disabled={!canPreviousPage}
          aria-label={t('labels.table.go-to-first')}
        >
          <ChevronDoubleLeft className="h-6 w-6 stroke-2" />
        </Button.Primary>
        <Button.Primary
          size="none"
          className="p-2"
          onClick={() => handleTableChangeAndScrollToTopIfEnabled(previousPage)}
          disabled={!canPreviousPage}
          aria-label={t('labels.table.go-to-prev')}
        >
          <ChevronLeft className="h-6 w-6 stroke-2" />
        </Button.Primary>
        <TablePageNumbers {...{ pageIndex, gotoPage, pageCount }} />
        <Button.Primary
          size="none"
          className="p-2"
          onClick={() => handleTableChangeAndScrollToTopIfEnabled(nextPage)}
          disabled={!canNextPage}
          aria-label={t('labels.table.go-to-next')}
        >
          <ChevronRight className="h-6 w-6 stroke-2" />
        </Button.Primary>
        <Button.Primary
          size="none"
          className="p-2"
          onClick={() =>
            handleTableChangeAndScrollToTopIfEnabled(gotoPage, pageCount - 1)
          }
          disabled={!canNextPage}
          aria-label={t('labels.table.go-to-last')}
        >
          <ChevronDoubleRight className="h-6 w-6 stroke-2" />
        </Button.Primary>
      </div>
      <div className="items-center space-x-2 space-y-4 px-4 pt-4 sm:flex sm:space-y-0 md:pt-0">
        <span className="font-bold">{`${t('common.all')}: ${
          totalEntries ?? 0
        }`}</span>
        <span>
          <span>{t('common.page')} </span>
          <strong>
            {t('common.of', {
              current: pageIndex + 1,
              total: pageOptions.length,
            })}
          </strong>
        </span>
        <div className="flex items-center space-x-2">
          <span>{t('common.show')} </span>
          <Select
            value={pageSize}
            onChange={(e) =>
              handleTableChangeAndScrollToTopIfEnabled(
                setPageSize,
                Number(e.target.value)
              )
            }
            className="pr-10"
            aria-label={t('labels.table.count-per-page')}
          >
            {pageSizeOptions.map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </Select>
        </div>
      </div>
    </div>
  );
}

TablePagination.propType = {
  gotoPage: PropTypes.func,
  previousPage: PropTypes.func,
  nextPage: PropTypes.func,
  canPreviousPage: PropTypes.bool,
  canNextPage: PropTypes.bool,
  pageCount: PropTypes.number,
  pageIndex: PropTypes.number,
  pageOptions: PropTypes.array,
  pageSize: PropTypes.number,
  setPageSize: PropTypes.func,
  pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
  withBackToTop: PropTypes.bool,
};

export default TablePagination;
