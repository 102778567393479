import { useTranslation } from 'react-i18next';
import { cn } from 'lib/utils';
import { ChevronLeft, ChevronRight } from 'components/icons';
import { callAll } from 'utils';
import { useCarousel } from './context';

export function CarouselBaseNext({ onClick, ...props }) {
  const { t } = useTranslation();
  const { carousel } = useCarousel();
  return (
    <button
      onClick={callAll(() => carousel.slideNext(), onClick)}
      aria-label={t('labels.carousel.next')}
      {...props}
    />
  );
}

export function CarouselBasePrev({ onClick, ...props }) {
  const { t } = useTranslation();
  const { carousel } = useCarousel();

  return (
    <button
      onClick={callAll(() => carousel.slidePrev(), onClick)}
      aria-label={t('labels.carousel.prev')}
      {...props}
    />
  );
}

const classes = [
  'z-20',
  'absolute inset-0',
  'disabled:hidden focus:outline-none smd-focus-visible-primary focus-visible:rounded-sm',
  'lg:w-10 lg:h-auto lg:mx-0 lg:bg-transparent lg:rounded-none lg:text-inherit lg:p-0',
  'w-8 h-8 mx-4 rounded-full text-smd-accent p-1',
  'stroke-2',
];

export function CarouselPrev({ className, children, ...rest }) {
  const { carousel } = useCarousel();

  if (carousel.isBeginning || carousel.activeIndex === 0) {
    return null;
  }

  return (
    <CarouselBasePrev
      className={cn(classes, 'right-auto', className)}
      {...rest}
    >
      {children || <ChevronLeft />}
    </CarouselBasePrev>
  );
}

export function CarouselNext({ className, children, ...rest }) {
  const { carousel, slidesCount } = useCarousel();

  if (carousel.isEnd || carousel.activeIndex === slidesCount - 1) {
    return null;
  }

  return (
    <CarouselBaseNext className={cn(classes, 'left-auto', className)} {...rest}>
      {children || <ChevronRight />}
    </CarouselBaseNext>
  );
}
