import { useTranslation } from 'react-i18next';

import {
  GuideStepFooter,
  GuideStepImage,
  GuideStepVideo,
  TGuideStep,
  TImageAsset,
  TVideoAsset,
} from 'components/Guide';
import { X } from 'components/icons';
import { SquareButton } from 'components/forms';

type GuideModalProps = {
  guideLength: number;
  currentStep: number;
  buttonLabel: string;
  closeGuide: () => void;
  goToNextStep: () => void;
  completeGuide: () => void;
  step: TGuideStep;
  imageAsset?: TImageAsset;
  videoAsset?: TVideoAsset;
};

export function GuideModal(props: GuideModalProps) {
  const { t } = useTranslation();

  return (
    <div className="fixed inset-0 z-[60] flex items-center justify-center p-4">
      <div className="relative flex max-h-full w-full max-w-[43rem] flex-col overflow-auto bg-white">
        <div className="flex items-start justify-between border-b-1 px-6 pb-2 pt-4">
          <p className="text-smd-h4 font-semibold leading-[1.375rem]">
            {props.step.title}
          </p>

          <SquareButton
            onClick={props.closeGuide}
            aria-label={t('labels.common.close')}
            className="h-5 w-5 p-0"
          >
            <X strokeWidth="3" />
          </SquareButton>
        </div>

        <div className="flex flex-col justify-between gap-4 p-4">
          {props.videoAsset && <GuideStepVideo videoAsset={props.videoAsset} />}

          {props.imageAsset && <GuideStepImage imageAsset={props.imageAsset} />}

          <p>{props.step.text}</p>
        </div>

        <GuideStepFooter
          currentStep={props.currentStep}
          guideLength={props.guideLength}
          goToNextStep={props.goToNextStep}
          completeGuide={props.completeGuide}
          isLastStep={props.step.isLastStep}
          buttonLabel={props.buttonLabel}
          className="p-4"
        />
      </div>
    </div>
  );
}
