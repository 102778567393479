import { forwardRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { minutesInHour, secondsInHour, secondsInMinute } from 'date-fns';
import { cn } from 'lib/utils';
import { Form } from 'components/forms';
import { Clock } from 'components/icons';
import manualInputsRegistry from '../manualInputsRegistry';

import { useManualInputDefaultProps } from '../hooks';
import { MANUAL_INPUT_TYPE } from '../const';
import { ControlledMaskInput } from './MaskInput';

export function TimeInput(props) {
  const { t } = useTranslation();
  const newProps = useManualInputDefaultProps(props);

  const isPanel =
    newProps.getValues(`${newProps.prefix}.observations`)?.length > 0;

  const watchValue = isPanel
    ? newProps.watch(`${newProps.name}`) ?? ''
    : newProps.watch(`${newProps.prefix}.value`) ?? '';

  const [hours, minutes, seconds] = watchValue?.split(' : ');

  const [totalSeconds, setTotalSeconds] = useState(0);

  const removeNonNumericSymbols = (value) => {
    return value?.replace(/\D/g, '0');
  };

  const synonyms = newProps.getValues(
    `${newProps.prefix}.${newProps.namePrefix}.synonyms`
  );
  const hasSynonyms = synonyms?.length > 0;

  useEffect(() => {
    const hoursToCalculate = Number(removeNonNumericSymbols(hours));
    const minutesToCalculate = Number(removeNonNumericSymbols(minutes));
    const secondsToCalculate = Number(removeNonNumericSymbols(seconds));

    setTotalSeconds(
      Number(hoursToCalculate) * secondsInHour +
        Number(minutesToCalculate) * secondsInMinute +
        Number(secondsToCalculate)
    );

    if (isPanel) {
      newProps.setValue(
        `${newProps.prefix}.observations[${newProps.index}].totalSeconds`,
        totalSeconds
      );
    } else newProps.setValue(`${newProps.prefix}.totalSeconds`, totalSeconds);
  }, [hours, isPanel, minutes, newProps, seconds, totalSeconds]);

  const timeValidationRules = {
    validate: {
      max: () => {
        return minutes >= minutesInHour || seconds >= secondsInMinute
          ? t('errors.invalid-duration')
          : true;
      },
    },
    ...newProps.rules,
  };

  return (
    <div className="w-[256px]">
      <div className="relative">
        <Form.Input
          {...newProps}
          mask="99 : 99 : 99"
          as={MaskControlled}
          className="w-full"
          rules={timeValidationRules}
        />
        <Clock
          className={cn(
            'pointer-events-none absolute right-3 h-5 w-5 -translate-y-2/4 transform text-smd-accent',
            isPanel ? (hasSynonyms ? 'top-11' : 'top-5') : 'top-12'
          )}
        />
      </div>
    </div>
  );
}

const MaskControlled = forwardRef((props, ref) => {
  return (
    <ControlledMaskInput
      {...props}
      mask={props.mask}
      placeholder={props.placeholder}
      ref={ref}
    />
  );
});

MaskControlled.displayName = 'MaskControlled';

manualInputsRegistry.register(MANUAL_INPUT_TYPE.TIME, TimeInput);
