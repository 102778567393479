import Slider from 'components/Slider';
import { useAudioPlayerContext } from '../PodcastContext';
import { formatTime } from '../utils';
import styles from './ProgressBar.module.css';

export default function ProgressBar() {
  const { duration, progress, setProgress, audioReactRef, theme } =
    useAudioPlayerContext();

  const handleProgressChange = (choosenTime) => {
    setProgress(choosenTime);
    audioReactRef.current.currentTime = choosenTime;
  };

  const timeLeft = duration - progress;

  return (
    <div className="flex w-full items-center justify-between gap-x-4 py-1.5">
      <div className={theme.progress}>{formatTime(progress)}</div>
      <div className="w-full">
        <Slider
          min={0}
          max={duration}
          value={progress}
          onChange={handleProgressChange}
          className="h-1 bg-smd-gray-lighter"
          sliderClassName={styles.progress}
        />
      </div>
      <div className={theme.progress}>{formatTime(timeLeft)}</div>
    </div>
  );
}
