import { useTranslation } from 'react-i18next';
import { cn } from 'lib/utils';
import { useAudioPlayerContext } from '../PodcastContext';

interface FollowPodcastLink {
  label?: string;
  url?: string;
  icon?: string;
}

interface FollowOnProps {
  links: FollowPodcastLink[];
  className?: string;
}

export default function FollowOn({ links = [], className }: FollowOnProps) {
  const { t } = useTranslation();
  const { theme } = useAudioPlayerContext();
  return (
    <div
      className={cn(
        'flex items-center justify-start space-x-4 md:justify-start',
        className
      )}
    >
      {links && Boolean(links?.length) && (
        <span className={cn(theme.followLabel, 'shrink-0 font-semibold')}>
          {t('podcasts.follow-us')}
        </span>
      )}
      <div className="flex flex-wrap gap-2">
        {links?.map((link) => (
          <a key={link.url} target="_blank" href={link.url} rel="noreferrer">
            <img alt={link.label} src={link?.icon} className="h-6" />
          </a>
        ))}
      </div>
    </div>
  );
}
