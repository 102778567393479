import { forwardRef, useEffect, useRef } from 'react';
import { useTable, usePagination, useRowSelect, useSortBy } from 'react-table';
import { isEmpty, isNil } from 'ramda';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import LoadingOverlay from 'components/LoadingOverlay';
import { Checkbox } from 'components/forms';
import { SortArrows, SortDown, SortUp } from 'components/icons';
import NoDataCell from './Cells/NoDataCell';

const classSortIcons = 'ml-3 inline h-4';

function Table({
  columns,
  data,
  fetchData,
  loading,
  initialLoading,
  paginationComponent,
  initialPageSize = 20,
  withRowSelect = false,
  withColumnSort = false,
  onRowSelect,
  pageCount: controlledPageCount,
  totalEntries,
  resetPage,
  title,
  defaultSortBy = [],
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds, sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: initialPageSize,
        sortBy: defaultSortBy,
      },
      manualPagination: true,
      manualSortBy: true,
      disableMultiSort: true,
      autoResetSortBy: false,
      pageCount: controlledPageCount,
    },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (withRowSelect) {
        hooks.visibleColumns.push((columns) => [
          {
            id: 'selection',
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          },
          ...columns,
        ]);
      }
    }
  );

  useEffect(() => {
    fetchData?.({ pageIndex, pageSize, sortBy });
  }, [fetchData, pageIndex, pageSize, sortBy]);

  useEffect(() => {
    gotoPage(0);
  }, [resetPage, gotoPage]);

  useEffect(() => {
    const parsedRows = selectedFlatRows.map((row) => row.original);
    onRowSelect?.(parsedRows, selectedRowIds);
  }, [onRowSelect, selectedFlatRows, selectedRowIds]);

  const renderRow = (row) => {
    return (
      <tr
        {...row.getRowProps()}
        className={classnames(
          'border-b border-smd-gray-lighter',
          row?.isSelected
            ? 'bg-smd-accent-light hover:bg-smd-accent-lighter'
            : 'hover:bg-smd-gray-lightest'
        )}
      >
        {row.cells.map((cell) => (
          <td {...cell.getCellProps()} className="p-4">
            {cell.render('Cell')}
          </td>
        ))}
      </tr>
    );
  };

  const renderHeader = (headerGroup) => {
    return (
      <tr
        {...headerGroup.getHeaderGroupProps()}
        className="border-b border-smd-gray-lighter bg-smd-gray-lightest"
      >
        {headerGroup.headers.map((column) => (
          <th
            {...column.getHeaderProps(column.getSortByToggleProps())}
            className={classnames('whitespace-nowrap p-4 font-semibold')}
          >
            {column.render('Header')}
            {withColumnSort &&
              column.canSort &&
              (column.isSorted ? (
                column.isSortedDesc ? (
                  <SortDown className={classSortIcons} />
                ) : (
                  <SortUp className={classSortIcons} />
                )
              ) : (
                <SortArrows className={classSortIcons} />
              ))}
          </th>
        ))}
      </tr>
    );
  };

  return (
    <LoadingOverlay
      isLoading={initialLoading}
      className={classnames(loading && 'cursor-wait')}
    >
      <div
        className={classnames(
          'relative w-full overflow-x-auto overflow-y-visible',
          loading && 'pointer-events-none'
        )}
      >
        {title ? <div className="py-2 font-semibold">{title}</div> : null}
        <table
          {...getTableProps()}
          className="w-full table-auto text-left text-smd-main"
        >
          <thead>
            {headerGroups.map((headerGroup) => renderHeader(headerGroup))}
          </thead>
          <tbody {...getTableBodyProps()} className="text-sm">
            {(isNil(data) || isEmpty(data)) && (
              <tr className="border-b border-smd-gray-lighter">
                <NoDataCell />
              </tr>
            )}
            {page.map((row, index) => {
              prepareRow(row);
              return renderRow(row, index);
            })}
          </tbody>
        </table>
      </div>
      {paginationComponent?.({
        gotoPage,
        previousPage,
        nextPage,
        canPreviousPage,
        initialPageSize,
        canNextPage,
        pageCount,
        totalEntries,
        pageIndex,
        pageOptions,
        pageSize,
        setPageSize,
      })}
    </LoadingOverlay>
  );
}

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.func,
      ]).isRequired,
      accessor: PropTypes.string.isRequired,
    })
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  initialLoading: PropTypes.bool,
  paginationComponent: PropTypes.func,
  initialPageSize: PropTypes.number,
  withRowSelect: PropTypes.bool,
  withColumnSort: PropTypes.bool,
  onRowSelect: PropTypes.func,
  fetchData: PropTypes.func,
  pageCount: PropTypes.number,
  totalEntries: PropTypes.number,
  resetPage: PropTypes.any,
};

const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return <Checkbox ref={resolvedRef} {...rest} />;
});

export default Table;
